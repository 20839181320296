
.main{

  .banner{
    position: relative;
    .box{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      h1{
        font-size: 50px;
        color: #fff;
        line-height: 1;
        font-style: italic;
        text-align: center;
        margin-bottom: 7px;
      }
      .dr{
        width: 34.62px;
        height: 6.02px;
        background: #FF2B15;
        margin: 10px auto;
      }
      .f1{
        margin-top: 135px;
      }
    }
  }
  .equipment{
    height: 376px;
    padding: 40px 16px 45px;
    margin-bottom: 40px;
    background: url('./images/img_equipment.png') center center no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div{
      
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    h2{
      font-size: 30px;
      color: #FFFFFF;
      line-height: 34px;
      text-align: center;
      margin-bottom: 24px;
    }
    p{
      font-weight: bold;
      font-size: 16px;
      color: #FFFFFF;
      text-align: center;
      line-height: 20px;
    }
    a{
      display: flex;
      margin: 24px auto 0;
      margin-top: 24px;
      align-items: center;
      justify-content: center;
      background: #41110880;
      border: 1px solid #FFFFFF;
      border-radius: 46px;
      width: 200px;
      height: 48px;
      font-weight: bold;
      font-size: 16px;
      color: #FFFFFF;
      &::after{
        content: '';
        display: block;
        margin-left: 8px;
        width: 30px;
        height: 16px;
        background: url('./images/icon_learn_more.png') center center no-repeat;
      }
      &:hover{
        background: #99746e80;
      }
    }
  }

}

.homeTitle{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px auto 32px;
  h1{
    font-size: 30px;
    color: #411108;
    text-align: center;
    font-weight: bold;
    line-height: 33px;
  }
  div{
    width: 96px;
    height: 4px;
    margin-top: 15px;
    background: #DD3333;
  }
}

.card{
  .album{
    height: 600px;
    display: grid;
    padding: 0 16px;
    grid-template-columns: 166px 166px;
    grid-column-gap: 11px;
    grid-row-gap: 32px;
    overflow: hidden;
    &.tabletAlbum {
      grid-row-gap: 10px;
      height: 530px;
      h2 {
        font-size: 12px;
        line-height: 12px;
      }
      p {
        height: 20px;
        font-size: 10px;
        line-height: 10px;
      }
    }
    .bg{
      width: 166px;
      height: 210px;
      margin-bottom: 8px;
    }
    h2{
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: #411108;
      margin-bottom: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    p{
      height: 32px;
      font-weight: bold;
      overflow: hidden;
      font-size: 14px;
      line-height: 16px;
      color: #99746E;
      -webkit-line-clamp: 2;
      display:-webkit-box;
      -webkit-box-orient:vertical;
    }
  }
  .albumShowAll{
    height: auto;
  }
  .loadMore{
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin: 27px 0 8px;
    color: #6D4C47;
    justify-content: center;
    svg{
      width: 10px;
      margin: 1px 0 0 8px;
      transform: rotate(180deg);
    }
  }

  .videoBox{
    padding: 0 16px;

    & > div{
      width: 343px;
      height: 194px;
      margin-bottom: 16px;
      position: relative;
      .poster{
        position: absolute;
        left: 0;
        top: 0;
        width: 343px;
        height: 194px;
        z-index: 1;
      }
      .play{
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        width: 40px;
        height: 40px;
        z-index: 2;
        transition: transform 0.2s;
        transform: translate(-50%, -50%);
        &:hover{
          transform: scale(1.2) translate(-40%, -40%);
        }
      }
    }
    video{
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  

}
