.main {
    margin: 24px auto 40px;
    padding: 0 16px;

    img{
        width: 100%;
        margin-bottom: 16px;
    }

    .info {
        font-size: 16px;
        color: #6D4C47;
        line-height: 22px;
        margin-bottom: 16px;
    }
}