.main{
  .introduction{
    margin-bottom: 71px;
    justify-content: space-between;
    img{
      width: 100%;
      margin-bottom: 13px;
    }
    div{
      padding: 0 16px;
      margin-bottom: 24px;
      h1{
        font-weight: bold;
        font-size: 28px;
        color: #411108;
        line-height: 32px;
        margin: 16px auto;
        text-align: center;
      }
      p{
        font-size: 16px;
        color: #6D4C47;
        line-height: 22px;
      }
    }
  }
  .skatepark{
    margin-top: 70px;
    background: #FCF7F7;
    padding: 32px 16px 16px;
    h1{
      font-weight: bold;
      font-size: 28px;
      line-height: 32px;
      color: #411108;
      text-align: center;
      margin-bottom: 16px;
    }
    .desc{
      font-size: 16px;
      color: #6D4C47;
      line-height: 22px;
    }

    li{
      margin-top: 16px;

      img{
        width: 100%;
      }
      h2{
        font-weight: 700;
        font-size: 20px;
        color: #411108;
        margin: 16px 0 8px;
      }
    }
  }
  .combination{
    height: 333px;
    padding: 40px 16px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    background: url('./images/img_quipment_4.png') no-repeat;
    background-size: 100% auto;
    h2{
      font-weight: 700;
      margin-bottom: 8px;
      font-size: 22px;
      color: #FFFFFF;
    }
    p{
      font-size: 16px;
      color: #FFFFFF;
      line-height: 22px;
    }
  }
}