

.card {
  .album {
    .swiper {
      margin-bottom: 48px;
      overflow: hidden;
    }
    .scroll {
      display: flex;
      transition: transform 0.2s;
    }
  }
  .action {
    display: flex;
    justify-content: flex-end;
    div {
      border-radius: 50%;
      margin-left: 8px;
      &:hover {
        background: #ece3df;
      }
    }
    img {
      cursor: pointer;
    }
    .dis {
      border-radius: 50%;
      opacity: 0.5;
      background: #ece3df;
      &:hover {
        background: #ece3df;
      }
    }
  }
}



.customCard {
  margin: 80px 0 64px;
}
