.detail {
    .bigTitle {
        font-weight: bold;
        font-size: 28px;
        color: #411108;
        text-align: center;
        margin-bottom: 16px;
    }

    .midTitle {
        font-size: 24px;
        color: #99746E;
        
    }

    .tipBox{
        text-align: center;
    }

    .smallTitle {
        font-size: 15px;
        line-height: 17px;
        text-align: center;
        margin-bottom: 8px;
        color: #99746E;
    }

    .tip {
        border-radius: 4px;
        padding: 4px 8px;
        opacity: 0.7;
        background: #411108;
        font-size: 14px;
        color: #FFFFFF;
    }

    .share {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        color: #411108;
        margin: 16px 0 24px;

        img {
            width: 24px;
            height: 24px;
            margin-left: 8px;
            cursor: pointer;
        }
    }

    .info {
        font-size: 16px;
        color: #6D4C47;
        line-height: 22px;
    }

    .picture {
        margin: 16px auto 0;
    }

}