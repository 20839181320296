.main{
  width: 1400px;
  margin: 65px auto 120px;
  .introduction{
    display: flex;
    align-items: flex-end;
    margin-bottom: 71px;
    justify-content: space-between;
    img{
      width: 600px;
      height: 464px;
    }
    div{
      width: 768px;
      h1{
        font-weight: bold;
        font-size: 64px;
        color: #411108;
        line-height: 72px;
        margin-bottom: 24px;
      }
      p{
        font-size: 20px;
        color: #6D4C47;
        line-height: 29px;
      }
    }
  }
  .skatepark{
    margin-top: 70px;
    background: #FCF7F7;
    padding: 48px 52px 56px;
    h1{
      font-weight: bold;
      font-size: 64px;
      color: #411108;
      text-align: center;
      line-height: 72px;
      margin-bottom: 16px;
    }
    .desc{
      font-size: 20px;
      color: #6D4C47;
      line-height: 29px;
    }
    ul{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    li{
      width: 632px;
      margin-top: 24px;
      img{
        width: 632px;
        height: 248px;
      }
      h2{
        font-weight: 700;
        font-size: 40px;
        color: #411108;
        margin: 16px 0 8px;
      }
    }
  }
  .combination{
    height: 276px;
    display: flex;
    flex-direction: column;
    padding: 48px;
    margin-top: 40px;
    background: url('./images/img_quipment_4.png') no-repeat;
    h2{
      font-weight: 700;
      font-size: 40px;
      color: #FFFFFF;
      margin-bottom: 8px;
    }
    p{
      font-size: 20px;
      color: #FFFFFF;
      line-height: 29px;
    }
  }
}