.article{
  .content{
    padding: 0 16px;
    font-size: 16px;
    color: #6D4C47;
    line-height: 30px;
    margin-bottom: 16px;
    p {
      line-height: 22px;
    }
  }
  .onlyImg {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .aboutTitle {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 28px;
    color: #411108;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 16px;
  }

  .aboutImgs {
    padding: 0 16px;
    img {
      margin-bottom: 16px;
      &:last-of-type {
        margin-bottom: 40px;
      }
    }
  }

  .functions {
    padding: 0 16px;
    .function {
      padding-left: 22px;
      font-size: 16px;
      color: #6D4C47;
      margin-bottom: 8px;
      position: relative;
      &:last-of-type {
        margin-bottom: 0;
      }
      &::before {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: #D62D0A;
        // top: 50%;
        top: 8px;
        // transform: translateY(-50%);
        left: 0;
      }
    }
  }

  .aboutImg {
    margin: 16px 0;
    padding: 0 16px;
    img {
      width: 100%;
      margin-bottom: 16px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .experience {
    padding: 0 16px;
    margin-bottom: 40px;
  }
  .functions, .aboutImg, .experience {
    box-sizing: border-box;
  }
  .smartTitle{
    height: 112px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url('./images/ar2.png');
    background-size: 100% auto;
    margin-bottom: 16px;
    p{
      text-align: center;
      color: #FFFFFF;
      font-size: 14px;
      &:first-child{
        width: 343px;
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
      }
    }
  }
}