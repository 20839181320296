
.header {
  height: 90px;
  display: flex;
  font-size: 20px;
  align-items: center;
  width: 1350px;
  margin: 0 auto;
  .logo{
    margin-right: 284px;
  }
  nav{
    width: 850px;
    & > a{
      margin: 0 4px;
      padding: 8px 16px;
      position: relative;
      svg{
        width: 12px;
        position: relative;
        left: 6px;
        top: -2px;
        transform: rotate(180deg);
        display: inline-block;
      }
      div{
        display: none;
        padding-top: 26px;
        left: 0;
        top: 37px;
        position: absolute;
        z-index: 100000;
        ul{
          padding: 8px 0;
          background: #FFFFFF;
          border: 1px solid #D8D8D8;
        }
        li{
          padding: 10px 16px;
          font-size: 18px;
          color: #411108;
          height: 40px;
          display: flex;
          align-items: center;
          &:hover{
            background: #FFE9E9;
          }
          a{
            flex: 1;
            display: block;
          }
        }
      }
      &:hover{
        background: #D62D0A;
        border-radius: 22px;
        color: #fff;
        div{
          // display: block;
        }
        svg{
          transform: rotate(0);
        }
      }
    }
  }
  
  .action{
    font-size: 16px;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    span{
      cursor: pointer;
    }
    em{
      width: 1px;
      height: 24px;
      background: #BEA19C;
      margin: 0 16px 0 20px;
    }
  }
  .active{
    background: #D62D0A;
    border-radius: 22px;
    color: #fff;
  }
}