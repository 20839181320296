

.card {
  .date{
    background: rgba($color: #000000, $alpha: .5);
    color:#fff;
    line-height: 32px;
    padding:0 10px;
    font-size: 20px;
  }
  .swiperItem {
    flex-shrink: 0;
    width: 515px;
  }
  .swiperItemImg {
    height: 640px;
    margin-right: 24px;
    background-size: cover ;
    background-position: center;
  }
  .swiperItemTitle {
    display: block;
    font-weight: bold;
    font-size: 40px;
    color: #411108;
    margin: 24px 0 16px;
    line-height: 44px;
    &:hover {
      color: #d62d0a;
    }
  }
  .swiperItemTitleRow {
    display: block;
    font-weight: bold;
    font-size: 40px;
    min-height: 88px;
    color: #411108;
    margin: 24px 0 16px;
    line-height: 44px;
    box-sizing: border-box;
    padding-right: 5px;
    &:hover {
      color: #d62d0a;
    }
  }
  .swiperItemDesc {
    display: block;
    font-weight: bold;
    font-size: 24px;
    color: #99746e;
    padding-right: 25px;
    &:hover {
      color: #d62d0a;
    }
  }
  .action {
    display: flex;
    justify-content: flex-end;
    div {
      border-radius: 50%;
      margin-left: 8px;
      &:hover {
        background: #ece3df;
      }
    }
    img {
      cursor: pointer;
      object-fit: cover;
    }
    .dis {
      border-radius: 50%;
      opacity: 0.5;
      background: #ece3df;
      &:hover {
        background: #ece3df;
      }
    }
  }
  .brief {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
