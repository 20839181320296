.main {
  width: 1400px;
  margin: 65px auto 120px;

  .bigTitle {
    font-weight: bold;
    font-size: 64px;
    color: #411108;
    margin: 50px 0 0px;
    border-bottom: 8px solid #DD3333;
    width: 400px;
  }

  .midTitle {
    font-weight: bold;
    font-size: 24px;
    color: #99746e;
    margin: 50px 0 0px;
  }

  .smallTitle {
    font-size: 16px;
    color: #99746e;
    margin: 50px 0 0px;
  }
  .load-more {
    font-size: 24px;
    width: 150px;
    cursor: pointer;
    margin: 40px auto 20px;
    text-align: center;
    color: #99746E;
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid;
  }
  .card-title{
    
  }
}
