.main {
  width: 1400px;
  margin: 65px auto 120px;

  .bigTitle {
    font-weight: bold;
    font-size: 40px;
    color: #411108;
  }

  .midTitle {
    font-weight: bold;
    font-size: 24px;
    color: #99746E;
  }

  .smallTitle {
    font-size: 16px;
    color: #99746E;
  }

  .picPart1 {

    .fl_spb {
      display: flex;
      justify-content: space-between;
    }

    .picBox {
      position: relative;
      width: 856px;
      height: 568px;

      .picture {
        width: 100%;
        height: 100%;
      }

      .tip {
        position: absolute;
        right: 12px;
        bottom: 8px;
        width: 76px;
        height: 24px;
        opacity: 0.7;
        background: #411108;
        border-radius: 4px;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 24px;
        text-align: center;
      }
    }

    .picBoxR {
      position: relative;
      width: 512px;
      height: 378px;

      .picture {
        width: 100%;
        height: 100%;
      }

      .tip {
        position: absolute;
        right: 12px;
        bottom: 8px;
        width: 76px;
        height: 24px;
        opacity: 0.7;
        background: #411108;
        border-radius: 4px;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 24px;
        text-align: center;
      }
    }

  }

  .picPart2 {
    display: flex;
    justify-content: space-between;
    margin-top: 72px;

    .item {

      .posr {
        position: relative;
        width: 446px;
        height: 304px;

        .picture {
          width: 100%;
          height: 100%;
        }

        .tip {
          position: absolute;
          right: 12px;
          bottom: 8px;
          width: 76px;
          height: 24px;
          opacity: 0.7;
          background: #411108;
          border-radius: 4px;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 24px;
          text-align: center;
        }
      }

    }

  }

  .picPart3 {
    margin-top: 72px;

    .bigTitle {
      font-weight: bold;
      font-size: 40px;
      line-height: 45px;
      color: #411108;
    }

    .item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 56px;

      .picture {
        width: 208px;
        height: 144px;
      }

      .info {
        width: 1167px;

        .tip {
          padding: 4px 8px;
          opacity: 0.7;
          background: #411108;
          border-radius: 4px;
          font-size: 14px;
          color: #FFFFFF;
          margin-right: 16px;
        }
      }
    }
  }

  .picPart4 {
    margin-top: 72px;

    .item {
      margin-bottom: 53px;

      .tip {
        padding: 4px 8px;
        opacity: 0.7;
        background: #411108;
        border-radius: 4px;
        font-size: 14px;
        color: #FFFFFF;
        margin-right: 16px;
      }
    }
  }
}