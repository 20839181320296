.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    // border-top: 1px solid #f9f9f9;
    border-top: 1px solid #EDE2E0;
    .logo{
        width: 66px;
        height: 66px;
        margin-bottom: 35px;
    }
    .icon{
        display: flex;
        align-items: flex-start;
        margin-bottom: 32px;
        img{
            margin: 0 8px;
            width: 48px;
        }
    }
    // h1{
    //     font-size: 24px;
    //     color: #411108;
    //     font-weight: bold;
    //     line-height: 27px;
    //     margin-bottom: -9px;
    // }
    // h2{
    //     font-weight: bold;
    //     font-size: 18px;
    //     color: #411108;
    //     line-height: 24px;
    //     margin: 24px 0 8px;
    // }
    p {
      font-size: 18px;
      color: #411108;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .title {
      font-weight: bold;
    }
    .content {
      flex-direction: column;
      line-height: 20px;
      height: auto;
      a {
        color: #411108;
        font-size: 18px;
      }
      span {
        font-size: 18px;
      }
    }
}
.copyright{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0 0;
    height: 44px;
    font-size: 14px;
    color: #6C4B46;
    line-height: 20px;
    background: #FCF7F7;
}