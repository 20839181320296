
:global {
  video::--webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none !important;
  }
}
.card{
  
  .videoBox{
    padding: 0;

    & > div{
      width: 100%;
      // height: 194px;
      // margin-bottom: 16px;
      position: relative;
      overflow: hidden;
      .poster{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 194px;
        z-index: 1;
        margin: 0;
      }
      .play{
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        width: 40px;
        height: 40px;
        z-index: 2;
        transition: transform 0.2s;
        transform: translate(-50%, -50%);
        &:hover{
          transform: scale(1.2) translate(-40%, -40%);
        }
      }

      video{
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    
  }
  

}
