.news-title-box {
  width: 100%;
  // background-image: url("../../../../public/news/img_news_banner.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px 20px;
}
.title-content {
  width: 1400px;
  margin: auto;
}

.tag-item {
  font-size: 15px;
  a {
    margin-right: 40px;
    // padding: 5px 10px;
    cursor: pointer;
    color: #99746E;
    padding: 10px 0;
  }
  .active {
    color: #411108;
    font-weight: bolder;
    border-bottom: 5px solid #D33;

  }
}
