.header {
  height: 44px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 29;
  width: 100%;
  overflow: hidden;
  background: #fff;
  > div{
    padding: 0 16px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo{
      width: 24px;
    }
    .logoW{
      display: none;
    }
    .actionw{
      display: none;
    }
    > div {
      display: flex;
      .actionIcon{
        width: 24px;
        margin-left: 16px;
      }
    }
  }
  
  nav{
   color: #fff;
   margin-top: 16px;
   li{

    height: 44px;
    overflow: hidden;

    &.show{
      height: auto;
      svg{
        transform: rotate(0);
      }
    }

    > div{
      font-weight: bold;
      height: 44px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      font-size: 18px;
      color: #FFFFFF;
      &:active{
        background: #6D4C47;
      }
    }
    
    svg{
      width: 10px;
      margin-top: 2px;
      transition: transform .2s;
      transform: rotate(180deg);
    }
    .openArrow{
      transform: rotate(0);
    }
   }
   dl{
    background: #4f1c12;
   }
   dd{
    font-weight: normal;
    font-size: 15px;
    display: flex;
    align-items: center; 
    color: #FFFFFF;
    padding: 0 16px;
    height: 36px;
    &:active{
      background: #6D4C47;
    }
   }
  }

  &.open{
    min-height: 100vh;
    overflow-y: auto;
    background: #411108;
    .logoW{
      width: 24px;
      display: block;
    }
    .logo{
      display: none;
    }
    .action{
      display: none;
    }
    .actionw{
      display: block;
    }
  }
}