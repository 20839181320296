.footer{
    border-top: 1px solid #EDE2E0;
    & > div:first-child{
        width: 1400px;
        margin: 30px auto 0;
    }
    .icon{
        height: 66px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;
    }
    .share{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        img{
            width: 48px;
            height: 48px;
            margin-left: 8px;
            cursor: pointer;
        }
    }
    h2{
        font-weight: 700;
        font-size: 20px;
        color: #411108;
        line-height: 29px;
    }
    .copyright{
        height: 44px;
        background: #FCF7F7;
        // font-weight: 500;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6C4B46;
        margin-top: 28px;
    }
    table{
        margin-top: 22px;
    }
    td{
        line-height: 30px;
        font-size: 18px;
        color: #411108;
    }
}