.navigater{
    background: #F5EFEE;
    padding: 22px 0;
    height: 160px;
    h1{
        font-weight: 700;
        font-size: 56px;
        color: #6D4C47;
        margin-bottom: 8px;
    }
    div{
        width: 1400px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .path{
        color: #6D4C47;
        font-size: 20px;
        span{
            color: #B6908B;
        }
    }
}