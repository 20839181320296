.privacy {
  padding: 30px;
  p {
    font-size: 16px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }
}