.article{
  width: 1400px;
  margin: 56px auto 120px;
  font-size: 20px;
  color: #6D4C47;
  letter-spacing: -0.2px;
  line-height: 29px;
  p{
    margin: 0 0 24px;
  }
  img{
    max-width: 100%;
    margin: 0 auto 24px;
  }
  .smartTitle{
    font-weight: 700;
    font-size: 48px;
    color: #FFFFFF;
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('./images/ar2.png');
    background-size: 100% auto;
    margin-bottom: 24px;
    flex-direction: column;
    p{
      margin: 0;
      &:first-child{
        line-height: 58px;
      }
    }
  }
  .secTitle{
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 4px;
  }
  .introduce {
    display: flex;
    .mainImg{
      max-width: 100%;
      margin-right: 38px;
      // margin: 24px auto;
    }
  }
  .aboutTitle {
    height: 90px;
    line-height: 90px;
    font-weight: 700;
    font-size: 64px;
    color: #411108;
    margin-bottom: 25px;
    margin-top: 50px;
  }
  .aboutContent {
    margin-top: 24px;
  }
  .function {
    margin: 0;
    padding-left: 28px;
    height: 29px;
    font-size: 20px;
    color: #6D4C47;
    line-height: 29px;
    margin-bottom: 10px;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      width: 12px;
      height: 12px;
      transform: rotate(90deg);
      background: #D62D0A;
      border-radius: 9px;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .aboutImg {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 40px;
    img {
      margin: 0;
    }
    // align-items: center;
  }
  
}
.videoBox{
  display: flex;
  justify-content: center;
  width: 964px;
  height: 550px;
  // border: 1px solid green;
  & > div{
    width: 100%;
    height: 550px;
    position: relative;

    .poster{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 550px;
      z-index: 1;
    }
    .play{
      position: absolute;
      top: 50%;
      left: 50%;
      cursor: pointer;
      width: 80px;
      height: 80px;
      z-index: 2;
      transition: transform 0.2s;
      transform: translate(-50%, -50%);
      &:hover{
        transform: scale(1.2) translate(-40%, -40%);
      }
    }
  }
  video{
    width: 100%;
    height: 100%;
    display: block;
  }
}