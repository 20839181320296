

.homeTitle{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px auto 32px;
  h1{
    font-size: 30px;
    color: #411108;
    text-align: center;
    font-weight: bold;
    line-height: 33px;
  }
  div{
    width: 96px;
    height: 4px;
    margin-top: 15px;
    background: #DD3333;
  }
}
