.article{
  width: 1400px;
  margin: 56px auto 120px;

  font-size: 20px;
  color: #6D4C47;
  letter-spacing: -0.2px;
  line-height: 29px;
  
  .introduce {
    display: flex;
    .mainImg{
      max-width: 100%;
      margin-right: 38px;
      // margin: 24px auto;
    }
  }
  .title{
    font-weight: 700;
    font-size: 48px;
    color: #FFFFFF;
    height: 87px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('./images/ar1.png');
    background-size: 100% auto;
    margin-bottom: 24px;
  }

  .smartTitle{
    font-weight: 700;
    font-size: 48px;
    color: #FFFFFF;
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('./images/ar2.png');
    background-size: 100% auto;
    margin-bottom: 24px;
  }

  .sectionTitle {
    width: 680px;
    height: 72px;
    background-image: linear-gradient(270deg, #FFFFFF 0%, #F7D6CE 100%);
    margin: 80px 0 32px 0;
    font-weight: 700;
    font-size: 40px;
    color: #411108;
    line-height: 72px;
    padding-left: 16px;
  }

  .newsContainer {
    display: flex;
    // gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    .newsItem {
      width: calc(50% - 11px);
      // display: flex;
      .newsImg {
        width: 100%;
        margin-bottom: 20px;
        img {
          width: 100%;
        }
      }
      .newsBrief {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .videoBox{
    display: flex;
    justify-content: space-between;
    width: 100%;
    // border: 1px solid green;
    & > div{
      width: calc(50% - 11px);
      height: 388px;
      position: relative;

      .poster{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 388px;
        z-index: 1;
      }
      .play{
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        width: 80px;
        height: 80px;
        z-index: 2;
        transition: transform 0.2s;
        transform: translate(-50%, -50%);
        &:hover{
          transform: scale(1.2) translate(-40%, -40%);
        }
      }
    }
    video{
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}