.main {
  .pageTitle {
    font-weight: 700;
    font-size: 28px;
    color: #ffffff;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: url('./images/header-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .title {
    box-sizing: border-box;
    padding: 0 16px;
    font-size: 28px;
    color: #411108;
    text-align: center;
    margin: 16px 0;
    font-weight: 700;
  }

  .contentWrap {
    // padding-left: 16px;
    position: relative;
    box-sizing: border-box;
    padding: 0 16px;
    padding-left: 32px;
    .contentItem {
      color: #6d4c47;
      font-size: 16px;
      line-height: 22px;
      // font-face: HarmonyOS_Sans_SC;
      font-weight: 400;
      // line-height: 50px;
      letter-spacing: 0;
      // paragraph-spacing: 0;
      text-align: left;
      position: relative;
      margin-bottom: 10px;
      &::before {
        position: absolute;
        content: '';
        width: 8px;
        height: 8px;
        background: #6d4c47;
        left: -16px;
        border-radius: 100%;
        top: 8px;
        // transform: translateY(-50%);
      }
    }
  }

  .factors {
    display: flex;
    flex-wrap: wrap;
    .factor {
      width: 50%;
      display: flex;
      flex-direction: column;
      font-size: 16px;
      line-height: 22px;
      align-items: center;
      margin-bottom: 24px;
      img {
        margin-bottom: 9px;
      }
      p {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .mainContent {
    background: #fcf7f7;
    position: relative;
    padding: 27px 0;
    box-sizing: border-box;
    .subTitle {
      font-size: 20px;
      font-weight: 700;
      color: #411108;
      text-align: center;
      line-height: 22px;
      margin-bottom: 16px;
    }
    p {
      font-size: 16px;
      color: #6d4c47;
      letter-spacing: 0;
      line-height: 22px;
      // padding-left: 10px;
      padding: 16px;
      padding-bottom: 24px;
      box-sizing: border-box;
      position: relative;
    }
    .showBorder {
      &::after {
        bottom: 0;
        content: '';
        position: absolute;
        // width: 100%;
        left: 16px;
        right: 16px;
        height: 0.5px;
        background: #ede2e0;
      }
    }
    .aiPowered {
      padding: 0 16px;
      justify-content: space-around;
      display: flex;
      margin-top: 30px;
      // box-sizing: border-box;
      position: relative;
      padding-bottom: 20px;
    }
  }
}
