.navigater{
    width: 100%;
    background: #F5EFEE;
    padding: 8px 0;
    height: 72px;
    h1{
        font-weight: 700;
        font-size: 24px;
        color: #6D4C47;
        text-align: center;
    }
    div{
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .path{
        text-align: center;
        font-size: 15px;
        line-height: 22px;
        span{
            color: #B6908B;
        }
        a{
            color: #B6908B;
        }
    }
}