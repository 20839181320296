

.card{
  .album{
    display: grid;
    padding: 0 16px;
    grid-template-columns: 166px 166px;
    grid-column-gap: 11px;
    grid-row-gap: 32px;
    overflow: hidden;
  }
  .albumShowAll{
    height: auto;
  }
  .loadMore{
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin: 27px 0 8px;
    color: #6D4C47;
    justify-content: center;
    svg{
      width: 10px;
      margin: 1px 0 0 8px;
      transform: rotate(180deg);
    }
  }

  .videoBox{
    padding: 0 16px;

    & > div{
      width: 343px;
      height: 194px;
      margin-bottom: 16px;
      position: relative;
      .poster{
        position: absolute;
        left: 0;
        top: 0;
        width: 343px;
        height: 194px;
        z-index: 1;
      }
      .play{
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        width: 40px;
        height: 40px;
        z-index: 2;
        transition: transform 0.2s;
        transform: translate(-50%, -50%);
        &:hover{
          transform: scale(1.2) translate(-40%, -40%);
        }
      }
    }
    video{
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  

}
