.main {
  margin: 24px auto;

  .bigTitle {
    font-size: 20px;
    color: #411108;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .midTitle {
    font-size: 14px;
    color: #99746E;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .smallTitle {
    font-size: 13px;
    color: #99746E;
    margin-bottom: 10px;
  }

  .picPart5{
    margin-top: 16px;
    display: grid;
    grid-template-columns: 166px 166px;
    grid-column-gap: 11px;
    grid-row-gap: 24px;
    overflow: hidden;
    .bigTitle{
      height: 44px;
      overflow: hidden;
      font-size: 20px;
      color: #411108;
      line-height: 23px;
      margin-bottom: 8px;
    }
    .midTitle{
      height: 32px;
      overflow: hidden;
      font-size: 14px;
      color: #99746E;
      line-height: 16px;
      margin-bottom: 8px;
    }
    .smallTitle{
      font-size: 13px;
      color: #99746E;
      line-height: 15px;
    }
    
    img{
      height: 210px;
      margin-bottom: 8px;
    }
    .tipBox{
      position: relative;
    }
    .tip {
      position: absolute;
      right: 12px;
      bottom: 8px;
      width: 76px;
      height: 24px;
      opacity: 0.7;
      z-index: 2;
      background: #411108;
      border-radius: 4px;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 24px;
      text-align: center;
    }
  }

  .picPart1 {

    .fl_spb {
      display: flex;
      justify-content: space-between;
    }

    .picBox {
      position: relative;
      width: 100%;

      .picture {
        width: 100%;
        height: 100%;
      }

      .tip {
        position: absolute;
        right: 12px;
        bottom: 8px;
        width: 76px;
        height: 24px;
        opacity: 0.7;
        background: #411108;
        border-radius: 4px;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 24px;
        text-align: center;
      }
    }

    .picBoxR {
      position: relative;
      width: 512px;
      height: 378px;

      .picture {
        width: 100%;
        height: 100%;
      }

      .tip {
        position: absolute;
        right: 12px;
        bottom: 8px;
        width: 76px;
        height: 24px;
        opacity: 0.7;
        background: #411108;
        border-radius: 4px;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 24px;
        text-align: center;
      }
    }

  }

  .picPart2 {
    display: flex;
    justify-content: space-between;

    .item {

      .posr {
        position: relative;
        height: 304px;

        .picture {
          width: 100%;
          height: 100%;
        }

        .tip {
          position: absolute;
          right: 12px;
          bottom: 8px;
          width: 76px;
          height: 24px;
          opacity: 0.7;
          background: #411108;
          border-radius: 4px;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 24px;
          text-align: center;
        }
      }

    }

  }

  .picPart3 {

    .bigTitle {
      flex: 1;
      font-size: 20px;
      color: #411108;
      line-height: 23px;
      margin-bottom: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .midTitle{
      font-size: 14px;
      color: #99746E;
      line-height: 16px;
    }

    .item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;

      .picture {
        width: 120px;
        height: 92px;
        margin-right: 12px;
      }

      .info {
        .tip {
          padding: 4px 8px;
          opacity: 0.7;
          background: #411108;
          border-radius: 4px;
          font-size: 14px;
          color: #FFFFFF;
          margin-right: 16px;
        }
      }
    }
  }

  .picPart4 {

    .bigTitle{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .midTitle{
      height: 48px;
      line-height: 16px;
      overflow: hidden;
    }
      
    .smallTitle{
      font-size: 12px;
    }
    .item {
      margin-bottom: 24px;

      .tip {
        padding: 4px 8px;
        opacity: 0.7;
        background: #411108;
        border-radius: 4px;
        font-size: 12px;
        color: #FFFFFF;
        margin-right: 16px;
      }
    }
  }
}