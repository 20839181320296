.card {
  .list-box {
    overflow: auto;
    padding: 0 20px;
    // 隐藏滚动条
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .album {
    display: grid;
    grid-template-columns: repeat(auto-fill, 186px); /* 每列宽度固定为 200px，自动填充列 */
    gap: 10px; /* 间距 */
    &.tabletAlbum {
      grid-row-gap: 10px;
      height: 530px;
    }
  }
}
