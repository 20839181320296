.article {
  width: 1400px;
  margin: 20px auto 0;
  .article-title {
    font-weight: 700;
    background: url("../article/images/ar1.png");
    background-size: 100% 100%;
    color: #fff;
    margin-bottom: 16px;
    text-align: center;
    padding: 10px 0;
    p {
      font-weight: 700;
      height: initial;
      line-height: initial;
      color: #fff;
      font-size: 28px;
    }
    span {
      font-size: 16px;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
  .article-content-box {
    padding: 20px;
    .content {
      font-size: 20px;
      height: auto;
      margin: 20px 0;
      color: #6d4c47;
      line-height: 1.5;
    }
    .img-box {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
  }
}
