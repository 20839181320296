.title {
    width: 100%;
    height: 320px;
    text-align: center;
    line-height: 320px;
    font-weight: bold;
    font-size: 64px;
    color: #FFFFFF;
    background-image: url('../../../../public/news/img_news_banner.png');
    background-size: 100% 100%;
}