.main {
  width: 1400px;
  margin: 56px auto 120px;
}
.boxing {
  position: relative;
  .pageTitle {
    width: 100%;
    height: 87px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 48px;
    color: #ffffff;
    text-align: center;
    background-image: url(./images/header-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-bottom: 110px;
  }
  .top {
    height: 502px;
    width: 1300px;
    background: #fcf7f7;
    // border-bottom: 1px solid red;
    position: relative;
    box-sizing: border-box;
    padding-left: 56px;
    .topTitle {
      // height: 90px;
      // line-height: 90px;
      // font-family: HarmonyOS_Sans_Bold;
      font-weight: 700;
      font-size: 64px;
      color: #411108;
      margin-top: 32px;
      margin-bottom: 12px;
      // padding-top: 80px;
    }

    .contentWrap {
      box-sizing: border-box;
      padding-left: 40px;
      // padding-top: 140px;
      position: relative;
      .contentItem {
        color: #6d4c47;
        font-size: 20px;
        // font-face: HarmonyOS_Sans_SC;
        font-weight: 400;
        line-height: 50px;
        letter-spacing: 0;
        // paragraph-spacing: 0;
        text-align: left;
        position: relative;
        &::before {
          position: absolute;
          content: '';
          width: 10px;
          height: 10px;
          background: #6d4c47;
          left: -30px;
          border-radius: 100%;
          top: 50%;
          transform: translateY(-50%);
        }
        &:last-child {
          &::before {
            top: 50%;
            transform: translateY(-14px);
          }
        }
      }
    }

    .imgDesc {
      position: absolute;
      right: 96px;
      bottom: 25px;
    }

    .topImg1 {
      position: absolute;
      right: -92px;
      top: 50%;
      transform: translateY(-50%);
    }

    .rightProduct {
      position: absolute;
      right: 0;
      top: 10px;
    }
  }
  .middle {
    // height: 810px;
    margin-top: 120px;
    // border: 1px solid red;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .factor {
      position: absolute;
      // left: 100px;
      top: 0px;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: end;
    }
    img {
    }
  }

  .bottom {
    height: 1000px;
    margin-top: 186px;
    .title {
      font-size: 64px;
      color: #411108;
      text-align: center;
      font-weight: 700;
      margin-bottom: 48px;
    }
  }
}
