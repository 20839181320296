*, *::before, *::after {
    margin: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}
html, body {
    height: 100%;
    overflow-x: hidden;
}
ul,li{
    list-style: none;
    padding: 0;
}
a{
    color: #411108;
    text-decoration: none;
}
body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}
img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
}
input, button, textarea, select {
    font: inherit;
}
p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
}
#root, #__next {
    isolation: isolate;
}