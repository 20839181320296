.detail {
    width: 1400px;
    margin: 65px auto 120px;

    .bigTitle {
        font-weight: BoldMT;
        font-size: 40px;
        color: #411108;
    }

    .midTitle {
        font-weight: BoldMT;
        font-size: 24px;
        color: #99746E;
    }

    .smallTitle {
        font-size: 16px;
        color: #99746E;
    }

    .tip {
        padding: 4px 8px;
        opacity: 0.7;
        background: #411108;
        border-radius: 4px;
        font-size: 14px;
        color: #FFFFFF;
        margin-right: 16px;
    }

    .share {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: #411108;
        margin: 34px 0;

        img {
            width: 48px;
            height: 48px;
            margin-left: 8px;
            cursor: pointer;
        }
    }

    .info {
        font-weight: Sans;
        font-size: 20px;
        color: #6D4C47;
    }

    .picture {
        width: 976px;
        height: 460px;
        margin: 24px auto 0;
    }

    .item {
        display: flex;
        margin-bottom: 24px;

        .posr {
            position: relative;
            width: 168px;
            height: 112px;
            background-image: linear-gradient(180deg, #7b510000 0%, #7d261399 100%);
            margin-right: 16px;

            .picture {
                width: 100%;
                height: 100%;
            }

            .tip {
                position: absolute;
                right: 12px;
                bottom: 8px;
                width: 76px;
                height: 24px;
                opacity: 0.7;
                background: #411108;
                border-radius: 4px;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 24px;
                text-align: center;
            }
        }

        .info {
            width: 216px;
        }

    }
}