.main {
    width: 1400px;
    margin: 65px auto 120px;

    .picture {
        width: 1400px;
        height: 608px;
        margin-bottom: 24px;
    }

    .fl_spb {
        display: flex;
        justify-content: space-between;
        margin: 24px 0;
    }

    .picture2 {
        width: 686px;
        height: 462px;
    }

    .info {
        font-size: 20px;
        color: #6C4B46;
        line-height: 29px;
    }
}