.title {
    position: relative;
    img{
        width: 100%;
    }
    span{
        position: absolute;
        left: 50%;
        top: 50%;
        font-weight: bold;
        font-size: 28px;
        z-index: 1;
        color: #FFFFFF;
        transform: translate(-50%, -50%);
    }
}