
.main{
  .banner{
    // height: 900px;
    padding-bottom: 46.8%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('./images/img_home_banner_photo.png');
    background-repeat: no-repeat;
    background-size: 100%;
    h1{
      font-size: 105px;
      color: #fff;
      text-align: center;
      margin-bottom: 15px;
    }
  }
}

.centerBox{
  width: 1400px;
  margin: 0 auto;
}

.homeTitle{
  margin-bottom: 48px;
  h1{
    font-weight: bold;
    font-size: 64px;
    color: #411108;
    margin-bottom: 19px;
    line-height: 72px;
  }
  div{
    width: 192px;
    height: 8px;
    background: #DD3333;
  }
}

.card{
  .videoBox{
    display: flex;
    & > div{
      width: 688px;
      height: 388px;
      margin-right: 24px;
      position: relative;
      .poster{
        position: absolute;
        left: 0;
        top: 0;
        width: 688px;
        height: 388px;
        z-index: 1;
      }
      .play{
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        width: 80px;
        height: 80px;
        z-index: 2;
        transition: transform 0.2s;
        transform: translate(-50%, -50%);
        &:hover{
          transform: scale(1.2) translate(-40%, -40%);
        }
      }
    }
    video{
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .album{
    .swiper{
      margin-bottom: 48px;
      overflow: hidden;
    }
    .scroll{
      display: flex;
      transition: transform 0.2s;
    }
    .swiperItem{
      flex-shrink: 0;
      width: 515px;
    }
    .swiperItemImg{
      height: 640px;
      margin-right: 24px;
      background-size: 100% auto;
    }
    .swiperItemTitle{
      display: block;
      font-weight: bold;
      font-size: 40px;
      color: #411108;
      margin: 24px 0 16px;
      line-height: 44px;
      &:hover{
        color: #D62D0A;
      }
    }
    .swiperItemTitleRow{
      display: block;
      font-weight: bold;
      font-size: 40px;
      min-height: 88px;
      color: #411108;
      margin: 24px 0 16px;
      line-height: 44px;
      box-sizing: border-box;
      padding-right: 5px;
      &:hover{
        color: #D62D0A;
      }
    }
    .swiperItemDesc{
      display: block;
      font-weight: bold;
      font-size: 24px;
      color: #99746E;
      padding-right: 25px;
      &:hover{
        color: #D62D0A;
      }
    }
  }
  .action{
    display: flex;
    justify-content: flex-end;
    div{
      border-radius: 50%;
      margin-left: 8px;
      &:hover{
        background: #ece3df;
      }
    }
    img{
      cursor: pointer;
    }
    .dis{
      border-radius: 50%;
      opacity: 0.5;
      background: #ece3df;
      &:hover{
        background: #ece3df;
      }
    }
  }
  .brief {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.equipment{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 900px;
  margin-bottom: 120px;
  background-image: url('./images/img_equipment.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  div{
    width: 1400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  h2{
    font-weight: 700;
    line-height: 82px;
    font-size: 72px;
    color: #FFFFFF;
    margin-bottom: 32px;
  }
  p{
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    color: #FFFFFF;
  }
  a{
    display: flex;
    margin-top: 64px;
    align-items: center;
    justify-content: center;
    background: #41110880;
    border: 1px solid #FFFFFF;
    border-radius: 46px;
    width: 246px;
    height: 80px;
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
    &::after{
      content: '';
      display: block;
      margin-left: 16px;
      width: 39px;
      height: 24px;
      background: url('./images/icon_learn_more.png') no-repeat;
    }
    &:hover{
      background: #99746e80;
    }
  }
}

.customCard{
  margin: 80px 0 64px;
}

.m2{
  margin: 0 0 145px;
}
.m3{
  margin: 0 0 100px;
}
.m4{
  margin: 0 0 120px;
}