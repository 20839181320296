.item {
  .bg {
    width: 100%;
    height: 210px;
    margin-bottom: 8px;
  }
  .date {
    background: rgba($color: #000000, $alpha: 0.5);
    color: #fff;
    height: 20px;
    line-height: 20px;
    padding: 0 10px;
    font-size: 12px;
  }
  h2 {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #411108;
    margin-bottom: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  p {
    // height: 32px;
    font-weight: bold;
    font-size: 14px;
    color:#99746e;
    // line-height: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
